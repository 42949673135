<template>
  <div>
    <!-- Components -->
    <readAllNotificationsMutation />

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-arial">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-faro font-bold text-3xl">Benachrichtigungen</div>
        </div>

        <!-- Notifications -->
        <div v-for="notification in filteredNotifications" :key="notification" class="mt-3 md:w-1/3 px-5">
          <singleNotification :heading="notification.title" :text="notification.body" :unread="!notification.read" />
        </div>

        <div v-if="filteredNotifications.length === 0" class="w-full flex flex-col justify-center items-center mt-10 p-5 text-center">
          <div>Keine neuen Benachrichtigungen</div>
          <div class="text-new-midGrey">Sieht aus, als hättest du alle älteren Benachrichtigungen bereits gelesen</div>
        </div>

        <!-- Scroll Space -->
        <div class="h-36"></div>

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import pushNotificationsPermission from '../../mixins/pushNotificationsPermission';

// Components
import singleNotification from './components/singleNotification.vue';

// Strapi
import readAllNotificationsMutation from './strapi/readAllNotificationsMutation.vue';

export default {
  name: 'notifications',
  mixins: [iosVibrations, detectNotch, pushNotificationsPermission],
  components: { singleNotification, readAllNotificationsMutation },

  computed: {
    notifications() {
      return this.$store.state.profile?.me?.userData?.data?.attributes?.notifications;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    readAllNotifications() {
      return this.$store.state.readAllNotifications;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    filteredNotifications() {
      const filteredNotifications = [];
      this.notifications.forEach((notification) => {
        // eslint-disable-next-line radix
        if (notification?.courseId === parseInt(this.currentCourseId)) {
          filteredNotifications.push(notification);
        }
      });
      filteredNotifications.sort((a, b) => Date.parse(b.time) - Date.parse(a.time));
      return filteredNotifications;
    },
  },

  mounted() {
    this.checkPushPermission();
    this.$store.commit('toggleNewNotification', false);
    this.queryMeEx()
      .then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          // set all notifications to read
          const readNotifications = [];
          this.notifications.forEach((notification) => {
            readNotifications.push({
              body: notification?.body,
              title: notification?.title,
              time: notification?.time,
              route: notification?.route,
              read: true,
              courseId: notification?.courseId,
            });
          });
          this.readAllNotifications({ id: result.data.me.userData.data.id, notifications: readNotifications });
        }
      });
  },

  beforeUnmount() {
    this.queryMeEx();
  },
};

</script>
